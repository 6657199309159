import React from 'react';
import styles from './Event.module.scss';

export default function Event({ event }) {
  const localeStartDateTimeString = () => {
    const date = new Date(Date.parse(event.startTime));
    const timezonePostfix = `${Intl.DateTimeFormat().resolvedOptions().timeZone} (Your Local Time)`;
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} ${timezonePostfix}`;
  }

  const localeEndDateTimeString = () => {
    const date = new Date(Date.parse(event.endTime));
    const timezonePostfix = `${Intl.DateTimeFormat().resolvedOptions().timeZone} (Your Local Time)`;
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} ${timezonePostfix}`;
  }

  return (
    <React.Fragment>
      <div class={styles.event}>
        <img class={styles.img} src={event.imagePath} />
        <div class={styles.textContainer}>
          <p class={styles.title}>{event.title}</p>
          <p class={styles.description}>Description: {event.description}</p>
          <p class={styles.startTime}>Start Time: {localeStartDateTimeString()}</p>
          <p class={styles.endTime}>End Time: {localeEndDateTimeString()}</p>
          <p class={styles.ownerName}>Event Organizer: {event.owner.name}</p>
          {!!event.venue && (<p class={styles.venueName}>{venue.name}</p>)}
          {event.virtualVenuePath && (
            <div class={styles.virtualVenueBtnContainer}>
              <button
                class={styles.virtualVenueBtn}
                onClick={() => { window.location.assign(event.virtualVenuePath) }}
              >
                Join Video Call
              </button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
