import React, { useState, useEffect } from 'react';
import sharedPropsType from '../../Shared/sharedPropsType';

import InvitationRequestWizard from '../../InvitationRequestWizard/components/InvitationRequestWizard';
import LoginWizard from '../../LoginWizard/components/LoginWizard';
import Feedback from '../../Feedback/components/Feedback';

import { deleteSession } from '../../Account/components/restApi';

import styles from './Header.module.scss';

const Header = ({ authenticatedUser, sharedAssetsPaths }) => {
  const [navExpanded, setNavExpanded] = useState(false);

  const [showInvitationRequestWizard, setShowInvitationRequestWizard] = useState(false);
  const [loginWizardOpen, setLoginWizardOpen] = useState(false);

  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackVariant, setFeedbackVariant] = useState(null);

  const clearFeedback = () => {
    setFeedbackMessage(null);
    setFeedbackVariant(null);
  };

  const openLoginWizard = (e) => {
    e.preventDefault();
    setLoginWizardOpen(true);
  };

  const openInvitationRequestWizard = (e) => {
    e.preventDefault();
    setShowInvitationRequestWizard(true);
  };

  const displayPreferenceModal = (e) => {
    e.preventDefault();
    window.displayPreferenceModal();
  }

  const logout = () => {
    deleteSession({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          localStorage.setItem("IsibuteFeedbackMessage", "You've been logged out of your account.");
          localStorage.setItem("IsibuteFeedbackVariant", "success");
          window.location.assign("/");
        } else {
          setFeedbackMessage(data.message);
          setFeedbackVariant("error");
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => {
        setFeedbackMessage(feedbackMessage);
        setFeedbackVariant(feedbackVariant);
      },
      body: {
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  };

  useEffect(() => {
    setFeedbackMessage(localStorage.getItem("IsibuteFeedbackMessage"));
    setFeedbackVariant(localStorage.getItem("IsibuteFeedbackVariant"));
  }, []);

  return (
    <React.Fragment>
      <header>
        <a id={styles.logoContainer} href={!authenticatedUser ? "/" : "/feed"}>
          <img id={styles.logoImage} src={sharedAssetsPaths.isibuteLogo} />
          <p id={styles.logoText}>ISIBUTE</p>
        </a>
        <a className={styles.icon} onClick={() => { setNavExpanded(!navExpanded) }}>&#9776;</a>
        <nav className={navExpanded ? styles.expandedNavBar : styles.collapsedNavBar} id={styles.headerNavBar}>
          {!!authenticatedUser && <a href="/feed">Feed</a>}
          <a href="/discourse">Discourse</a>
          {!!authenticatedUser && authenticatedUser.canViewEvents && <a href="/events">Events</a>}
          {!!authenticatedUser && authenticatedUser.canViewGroups && <a href="/groups">Groups</a>}
          {!!authenticatedUser && <a href={`/users/${authenticatedUser.id}`}>Profile</a>}
          {!!authenticatedUser && <a href={`/your_account`}>Account</a>}
          <a onClick={displayPreferenceModal} id="termly-consent-preferences">Consent Preferences</a>
          {!!authenticatedUser && <a onClick={logout}>Log Out</a>}
          {!authenticatedUser && <a onClick={openInvitationRequestWizard}>Get an invitation</a>}
          {!authenticatedUser && <a onClick={openLoginWizard}>Log In</a>}
        </nav>
      </header>

      <div id={styles.headerSpacer}></div>

      <Feedback
        message={feedbackMessage}
        variant={feedbackVariant}
        closeFeedback={clearFeedback}
      />
      <InvitationRequestWizard
        sharedAssetsPaths={sharedAssetsPaths}
        showInvitationRequestWizard={showInvitationRequestWizard}
        closeInvitationRequestWizard={() => { setShowInvitationRequestWizard(false)}}
      />
      <LoginWizard
        wizardOpen={loginWizardOpen}
        handleCloseWizard={() => { setLoginWizardOpen(false) }}
      />
    </React.Fragment>
  );
};

Header.propTypes = {
  ...sharedPropsType,
};

export default Header;
