import { documentComponentFromData } from "./DocumentComponentRegistry";

class IsibuteDocument {
  constructor(data) {
    this.author = data?.author;
    this.publishedAt = data?.publishedAt;
    this.documentComponents = {};
    this.currentStartNode = null;
    this.currentEndNode = null;
    this.cursorStartPosition = 0;
    this.cursorEndPosition = 0;
    this.currentStartNodeCursorPosition = 0;
    this.currentEndNodeCursorPosition = 0;

    if (data?.document?.documentComponents) {
      Object.entries(data?.document?.documentComponents).forEach(([key, value]) => {
        this.documentComponents[key] = documentComponentFromData({ ...value, isibuteDocument: this });
      });
    }
  }

  getDocumentComponentByKey = (key) => {
    return this.documentComponents[key] || null;
  };

  getDocumentComponentByKeyPath = ({ keyPath }) => {
    const currentDocumentComponent = this.getDocumentComponentByKey(keyPath?.shift());

    if (keyPath?.length > 0) {
      return currentDocumentComponent?.getDocumentComponentByKeyPath({ keyPath }) || null;
    }

    return currentDocumentComponent;
  };

  updateDocumentComponentByKeyPath = ({ keyPath, updaterFn }) => {
    updaterFn(getDocumentComponentByKeyPath({ keyPath }));
  };

  newRandomHexidecimalKey = () => {
    const randomHexidecimal = () => {
      const hexadecimalDigits = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
      ];

      return Array.from(Array(32), () => {
        const randomIndex = Math.floor(Math.random() * 16);
        return hexadecimalDigits[randomIndex];
      }).join('');
    };

    let newKey = randomHexidecimal();

    while (Object.keys(this.documentComponents).includes(newKey)) {
      newKey = randomHexidecimal();
    }

    return newKey;
  };

  setEndOfRangeToStart = () => {
    this.cursorEndPosition = this.cursorStartPosition;
    this.currentEndNodeCursorPosition = this.currentStartNodeCursorPosition;
    this.currentEndNode = this.currentStartNode;
  };

  setStartOfRangeToEnd = () => {
    this.cursorStartPosition = this.cursorEndPosition;
    this.currentStartNodeCursorPosition = this.currentEndNodeCursorPosition;
    this.currentStartNode = this.currentEndNode;
  };

  arrowLeft = () => {
    if (this.cursorStartPosition == this.cursorEndPosition) {
      if (this.currentStartNodeCursorPosition > 1) {
        this.cursorStartPosition = this.cursorStartPosition - 1;
        this.currentStartNodeCursorPosition = this.currentStartNodeCursorPosition - 1;
        this.setEndOfRangeToStart();
      } else {
        const componentForArrowLeft = this.currentStartNode.getComponentForArrowLeft();

        if (componentForArrowLeft) {
          this.cursorStartPosition = this.cursorStartPosition - 1;
          this.currentStartNodeCursorPosition = componentForArrowLeft.unstyledContent.length;
          this.currentStartNode = componentForArrowLeft;
          this.setEndOfRangeToStart();
        }
      }
    } else {
      this.setEndOfRangeToStart();
    }
  };

  arrowRight = () => {
    if (this.cursorStartPosition == this.cursorEndPosition) {
      if (this.currentEndNodeCursorPosition < this.currentEndNode.unstyledContent.length) {
        this.cursorEndPosition = this.cursorEndPosition + 1;
        this.currentEndNodeCursorPosition = this.currentEndNodeCursorPosition + 1;
        this.setStartOfRangeToEnd();
      } else {
        const componentForArrowRight = this.currentEndNode.getComponentForArrowRight();

        if (componentForArrowRight) {
          this.cursorEndPosition = this.cursorEndPosition + 1;
          this.currentEndNodeCursorPosition = 1;
          this.currentEndNode = componentForArrowRight;
          this.setStartOfRangeToEnd();
        }
      }
    } else {
      this.setStartOfRangeToEnd();
    }
  };

  backspace = () => {
    if (this.cursorStartPosition == this.cursorEndPosition) {
      this.backspaceSingleCharacterFromEnd();

      this.setStartOfRangeToEnd();
    } else {
      this.backspaceRange();
    }
  };

  backspaceRange = () => {
    while (this.cursorStartPosition < this.cursorEndPosition) {
      this.backspaceSingleCharacterFromEnd();
    }
  }

  backspaceSingleCharacterFromEnd = () => {
    if (this.cursorEndPosition <= 1) {
      return null;
    }

    const newStylingAfterBackspace = () => {
      return this.currentEndNode.styling.map((oldStyle) => {
        let newStyle = {
          types: oldStyle.types,
        };

        if (oldStyle.start < this.currentEndNodeCursorPosition && oldStyle.end < this.currentEndNodeCursorPosition) {
          newStyle.start = oldStyle.start;
          newStyle.end = oldStyle.end;
        }

        if (oldStyle.start < this.currentEndNodeCursorPosition && oldStyle.end == this.currentEndNodeCursorPosition) {
          newStyle.start = oldStyle.start;
          newStyle.end = oldStyle.end - 1;
        }

        if (oldStyle.start < this.currentEndNodeCursorPosition && this.currentEndNodeCursorPosition < oldStyle.end) {
          newStyle.start = oldStyle.start;
          newStyle.end = oldStyle.end - 1;
        }

        if (oldStyle.start == this.currentEndNodeCursorPosition && this.currentEndNodeCursorPosition < oldStyle.end) {
          newStyle.start = oldStyle.start;
          newStyle.end = oldStyle.end - 1;
        }

        if (oldStyle.start == this.currentEndNodeCursorPosition && this.currentEndNodeCursorPosition == oldStyle.end) {
          newStyle = null;
        }

        if (this.currentEndNodeCursorPosition < oldStyle.start && this.currentEndNodeCursorPosition < oldStyle.end) {
          newStyle.start = oldStyle.start - 1;
          newStyle.end = oldStyle.end - 1;
        }

        return newStyle;
      }).filter((el) => {
        return el != null;
      });
    };

    if (this.currentEndNodeCursorPosition > 1) {
      const newValueForCurrentNode =
        this.currentEndNode.unstyledContent.substring(0, this.currentEndNodeCursorPosition - 1) +
        this.currentEndNode.unstyledContent.substring(this.currentEndNodeCursorPosition);
      this.currentEndNode.unstyledContent = newValueForCurrentNode;
      this.currentEndNode.styling = newStylingAfterBackspace();
      this.cursorEndPosition = this.cursorEndPosition - 1;
      this.currentEndNodeCursorPosition = this.currentEndNodeCursorPosition - 1;
    } else {
      const componentForBackspace = this.currentEndNode.getComponentForArrowLeft();

      if (componentForBackspace) {
        let documentComponentsToIterate = null;

        if (this.currentEndNode.documentComponentType == 'DocumentCitation') {
          documentComponentsToIterate = Object.values(this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations);
        } else {
          documentComponentsToIterate = Object.values(this.documentComponents);
        }

        documentComponentsToIterate.forEach((documentComponent) => {
          if (documentComponent.ordinal > this.currentEndNode.ordinal) {
            documentComponent.ordinal = documentComponent.ordinal - 1;
          }
        });

        const stylingAfterJoin = this.currentEndNode.joinStyling({
          firstHalfOfStyling: componentForBackspace.styling,
          secondHalfOfStyling: this.currentEndNode.styling,
        });

        const unstyledContentAfterJoin = this.currentEndNode.joinUnstyledContent({
          firstHalfOfUnstyledConent: componentForBackspace.unstyledContent,
          secondHalfOfUnstyledContent: this.currentEndNode.unstyledContent,
        });

        const componentForBackspaceUnstyledContentLengthBeforeJoin = componentForBackspace.unstyledContent.length;

        if (this.currentEndNode.documentComponentType == 'DocumentCitation') {
          this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations[this.currentEndNode.previousDocumentComponentId].unstyledContent = unstyledContentAfterJoin;
          this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations[this.currentEndNode.previousDocumentComponentId].styling = stylingAfterJoin;
          this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations[this.currentEndNode.previousDocumentComponentId].nextDocumentComponentId = this.currentEndNode.nextDocumentComponentId;
          if (this.currentEndNode.nextDocumentComponentId) {
            this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations[this.currentEndNode.nextDocumentComponentId].previousDocumentComponentId = this.currentEndNode.previousDocumentComponentId;
          }

          delete this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations[this.currentEndNode.id];
        } else {
          this.documentComponents[this.currentEndNode.previousDocumentComponentId].unstyledContent = unstyledContentAfterJoin;
          this.documentComponents[this.currentEndNode.previousDocumentComponentId].styling = stylingAfterJoin;
          this.documentComponents[this.currentEndNode.previousDocumentComponentId].nextDocumentComponentId = this.currentEndNode.nextDocumentComponentId;
          if (this.currentEndNode.nextDocumentComponentId) {
            this.documentComponents[this.currentEndNode.nextDocumentComponentId].previousDocumentComponentId = this.currentEndNode.previousDocumentComponentId;
          }

          delete this.documentComponents[this.currentEndNode.id];
        }

        this.cursorEndPosition = this.cursorEndPosition - 1;
        this.currentEndNodeCursorPosition = componentForBackspaceUnstyledContentLengthBeforeJoin;
        this.currentEndNode = componentForBackspace;
      }
    }
  };

  enter = () => {
    this.backspaceRange();

    const newNode = documentComponentFromData({
      id: this.newRandomHexidecimalKey(),
      ordinal: this.currentEndNode.ordinal + 1,
      documentComponentType: this.currentEndNode.documentComponentType,
      isibuteDocument: this,
    });

    const unstyledContentForPreviousNode = this.currentEndNode.unstyledContent.substring(0, this.currentEndNodeCursorPosition);
    const unstyledContentForNewNodePrefix = '\u200b';
    const unstyledContentForNewNode = unstyledContentForNewNodePrefix + this.currentEndNode.unstyledContent.substring(this.currentEndNodeCursorPosition);

    newNode.unstyledContent = unstyledContentForNewNode;
    newNode.previousDocumentComponentId = this.currentEndNode.id;
    newNode.nextDocumentComponentId = this.currentEndNode.nextDocumentComponentId;
    newNode.parentDocumentComponentId = this.currentEndNode.parentDocumentComponentId;

    let documentComponentsToIterate = null;

    if (this.currentEndNode.documentComponentType == 'DocumentCitation') {
      documentComponentsToIterate = Object.values(this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations);
    } else {
      documentComponentsToIterate = Object.values(this.documentComponents);
    }

    documentComponentsToIterate.forEach((documentComponent) => {
      if (documentComponent.ordinal >= newNode.ordinal) {
        documentComponent.ordinal = documentComponent.ordinal + 1;
      }
    });

    const stylingsAfterSplit = this.currentEndNode.splitStyling({
      splitIndex: this.currentEndNodeCursorPosition
    });

    newNode.styling = stylingsAfterSplit.secondHalfOfStyling;

    if (this.currentEndNode.documentComponentType == 'DocumentCitation') {
      this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations[newNode.id] = newNode;
      this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations[this.currentEndNode.id].unstyledContent = unstyledContentForPreviousNode;
      this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations[this.currentEndNode.id].nextDocumentComponentId = newNode.id;
      this.documentComponents[this.currentEndNode.parentDocumentComponentId].documentCitations[this.currentEndNode.id].styling = stylingsAfterSplit.firstHalfOfStyling;
      if (newNode.nextDocumentComponentId) {
        this.documentComponents[newNode.parentDocumentComponentId].documentCitations[newNode.nextDocumentComponentId].previousDocumentComponentId = newNode.id;
      }
    } else {
      this.documentComponents[newNode.id] = newNode;
      this.documentComponents[this.currentEndNode.id].unstyledContent = unstyledContentForPreviousNode;
      this.documentComponents[this.currentEndNode.id].nextDocumentComponentId = newNode.id;
      this.documentComponents[this.currentEndNode.id].styling = stylingsAfterSplit.firstHalfOfStyling;
      if (newNode.nextDocumentComponentId) {
        this.documentComponents[newNode.nextDocumentComponentId].previousDocumentComponentId = newNode.id;
      }
    }

    this.cursorEndPosition = this.cursorEndPosition + 1;
    this.currentEndNodeCursorPosition = 1;
    this.currentEndNode = newNode;

    this.setStartOfRangeToEnd();
  };

  addCharacter = (character) => {
    this.backspaceRange();

    const newStylingAfterNewChar = ({ stylingBeforeNewChar, cursorPositionBeforeNewChar }) => {
      return stylingBeforeNewChar.map((oldStyle) => {
        let newStyle = {
          types: oldStyle.types,
        };

        if (oldStyle.start < cursorPositionBeforeNewChar && oldStyle.end < cursorPositionBeforeNewChar) {
          newStyle.start = oldStyle.start;
          newStyle.end = oldStyle.end;
        }

        if (oldStyle.start < cursorPositionBeforeNewChar && oldStyle.end == cursorPositionBeforeNewChar) {
          newStyle.start = oldStyle.start;
          newStyle.end = oldStyle.end + 1;
        }

        if (oldStyle.start < cursorPositionBeforeNewChar && cursorPositionBeforeNewChar < oldStyle.end) {
          newStyle.start = oldStyle.start;
          newStyle.end = oldStyle.end + 1;
        }

        if (oldStyle.start == cursorPositionBeforeNewChar && cursorPositionBeforeNewChar < oldStyle.end) {
          newStyle.start = oldStyle.start;
          newStyle.end = oldStyle.end + 1;
        }

        if (oldStyle.start == cursorPositionBeforeNewChar && cursorPositionBeforeNewChar == oldStyle.end) {
          newStyle.start = oldStyle.start;
          newStyle.end = oldStyle.end + 1;
        }

        if (cursorPositionBeforeNewChar < oldStyle.start && cursorPositionBeforeNewChar < oldStyle.end) {
          newStyle.start = oldStyle.start + 1;
          newStyle.end = oldStyle.end + 1;
        }

        return newStyle;
      }).filter((el) => {
        return el != null;
      });
    };

    const newValueForCurrentNode =
      this.currentEndNode.unstyledContent.substring(0, this.currentEndNodeCursorPosition) +
      character +
      this.currentEndNode.unstyledContent.substring(this.currentEndNodeCursorPosition);

    this.currentEndNode.unstyledContent = newValueForCurrentNode;
    this.currentEndNode.styling = newStylingAfterNewChar({
      stylingBeforeNewChar: this.currentEndNode.styling,
      cursorPositionBeforeNewChar: this.currentEndNodeCursorPosition - 1
    });

    this.cursorEndPosition = this.cursorEndPosition + 1;
    this.currentEndNodeCursorPosition = this.currentEndNodeCursorPosition + 1;

    this.setStartOfRangeToEnd();
  };

  deepCopy = () => {
    const copy = new IsibuteDocument({ document: { documentComponents: {} } });

    copy.author = this.author;
    copy.publishedAt = this.publishedAt;
    copy.cursorStartPosition = this.cursorStartPosition;
    copy.cursorEndPosition = this.cursorEndPosition;
    copy.currentStartNodeCursorPosition = this.currentStartNodeCursorPosition;
    copy.currentEndNodeCursorPosition = this.currentEndNodeCursorPosition;

    Object.entries(this.documentComponents).forEach(([key, value]) => {
      copy.documentComponents[key] = value.deepCopy();
    });

    copy.currentStartNode = copy.getDocumentComponentByKeyPath({
      keyPath: this.currentStartNode?.keyPath()
    });

    copy.currentEndNode = copy.getDocumentComponentByKeyPath({
      keyPath: this.currentEndNode?.keyPath()
    });

    return copy;
  };
}

export default IsibuteDocument;
