import PropTypes from 'prop-types';
import React from 'react';

import styles from './InvitationRequestReceived.module.scss';

const InvitationRequestReceived = ({ isActive, sharedAssetsPaths, closeInvitationRequestWizard }) => {
  return isActive ? (
    <React.Fragment>
      <div id={styles.xComponentContainer}>
        <img src={sharedAssetsPaths.xComponent} id={styles.xComponent} onClick={closeInvitationRequestWizard}></img>
      </div>
      <section id={styles.mainContent}>
        <div>
          <img id={styles.partyPopper} src={sharedAssetsPaths.partyPopper256}></img>
        </div>
        <div id={styles.messageContainer}>
          <p id={styles.message}>Thanks for your request. We’ll be in touch with you about that invitation.</p>
        </div>
        <div>
          <p id={styles.homeCTA} onClick={closeInvitationRequestWizard}>Go Back Home</p>
        </div>
      </section>
    </React.Fragment>
  ) : (
    <></>
  );
};

InvitationRequestReceived.propTypes = {
  sharedAssetsPaths: PropTypes.shape({}).isRequired,
};

export default InvitationRequestReceived;
