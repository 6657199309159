class IsibuteDocumentComponent {
  constructor({
    id,
    unstyledContent,
    styling,
    ordinal,
    documentComponentType,
    parentDocumentComponentId,
    firstChildDocumentComponentId,
    lastChildDocumentComponentId,
    previousDocumentComponentId,
    nextDocumentComponentId,
    isibuteDocument,
  }) {
    this.id = id;
    this.unstyledContent = unstyledContent;
    this.styling = styling;
    this.ordinal = ordinal;
    this.documentComponentType = documentComponentType;
    this.parentDocumentComponentId = parentDocumentComponentId;
    this.firstChildDocumentComponentId = firstChildDocumentComponentId;
    this.lastChildDocumentComponentId = lastChildDocumentComponentId;
    this.previousDocumentComponentId = previousDocumentComponentId;
    this.nextDocumentComponentId = nextDocumentComponentId;
    this.isibuteDocument = isibuteDocument;
  }

  deepCopy = () => {
    return new this.constructor({
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling.map(style => {return {...style}}),
      ordinal: this.ordinal,
      documentComponentType: this.documentComponentType,
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      isibuteDocument: this.isibuteDocument,
    });
  };

  keyPath = () => {
    return [this.id];
  };

  getComponentForArrowLeft = () => {
    const previousDocumentComponent = this.getPreviousDocumentComponent();

    if (previousDocumentComponent?.lastChildDocumentComponentId) {
      return this.isibuteDocument.getDocumentComponentByKeyPath({
        keyPath: [
          previousDocumentComponent.id,
          previousDocumentComponent.lastChildDocumentComponentId,
        ],
      });
    }

    return previousDocumentComponent;
  };

  getPreviousDocumentComponent = () => {
    return this.isibuteDocument.getDocumentComponentByKeyPath({
      keyPath: [this.previousDocumentComponentId],
    });
  };

  getComponentForArrowRight = () => {
    const nextDocumentComponent = this.getNextDocumentComponent();

    if (nextDocumentComponent?.firstChildDocumentComponentId) {
      return this.isibuteDocument.getDocumentComponentByKeyPath({
        keyPath: [
          nextDocumentComponent.id,
          nextDocumentComponent.firstChildDocumentComponentId,
        ],
      });
    }

    return nextDocumentComponent;
  };

  getNextDocumentComponent = () => {
    return this.isibuteDocument.getDocumentComponentByKeyPath({
      keyPath: [this.nextDocumentComponentId],
    });
  };

  splitStyling = ({ splitIndex }) => {
    const firstHalfOfStyling = [];
    const secondHalfOfStyling = [];

    this.styling.forEach((style) => {
      if (style.start < splitIndex && style.end < splitIndex) {
        firstHalfOfStyling.push(style);
      }

      if (style.start < splitIndex && splitIndex <= style.end) {
        firstHalfOfStyling.push({
          start: style.start,
          end: splitIndex - 1,
          types: style.types,
        });
        secondHalfOfStyling.push({
          start: 0,
          end: style.end - splitIndex + 1,
          types: style.types,
        });
      }

      if (splitIndex == style.start && splitIndex <= style.end) {
        secondHalfOfStyling.push({
          start: 0,
          end: style.end - splitIndex + 1,
          types: style.types,
        });
      }

      if (splitIndex < style.start && splitIndex < style.end) {
        secondHalfOfStyling.push({
          start: style.start - splitIndex + 1,
          end: style.end - splitIndex + 1,
          types: style.types,
        });
      }
    });

    if (secondHalfOfStyling.length == 0) {
      secondHalfOfStyling.push({
        start: 0,
        end: 0,
        types: ['none'],
      });
    }

    return {
      firstHalfOfStyling,
      secondHalfOfStyling,
    };
  };

  joinStyling = ({ firstHalfOfStyling, secondHalfOfStyling }) => {
    const joinedStyling = [];

    firstHalfOfStyling.forEach((style) => {
      joinedStyling.push(style);
    });

    const endOfFirstHalfOfStyling = firstHalfOfStyling.sort((thisStyle, thatStyle) => {
      return thisStyle.end - thatStyle.end;
    }).pop().end;

    secondHalfOfStyling.forEach((style) => {
      if (style.start == 0) {
        joinedStyling.push({
          start: style.start + endOfFirstHalfOfStyling + 1,
          end: style.end + endOfFirstHalfOfStyling,
          types: style.types,
        });
      } else {
        joinedStyling.push({
          start: style.start + endOfFirstHalfOfStyling,
          end: style.end + endOfFirstHalfOfStyling,
          types: style.types,
        });
      }
    });

    return joinedStyling;
  };

  joinUnstyledContent = ({ firstHalfOfUnstyledConent, secondHalfOfUnstyledContent }) => {
    return firstHalfOfUnstyledConent + secondHalfOfUnstyledContent.substring(1);
  };
};

export default IsibuteDocumentComponent;