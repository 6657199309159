import React, { useState, useEffect } from 'react';

import { getDocument } from '../../DiscourseIndex/components/restApi';
import {
  setCursorPositionInDOM,
} from './RichTextEditerHelpers';

import DiscourseLoading from './DiscourseLoading';
import DiscoursePublishingInfoSection from './DiscoursePublishingInfoSection';
import DiscourseMainSection from './DiscourseMainSection';
import DiscourseDebugger from './DiscourseDebugger';

import DocumentManager from './DocumentManager';

import styles from './Discourse.module.scss';

const Discourse = ({ id }) => {
  const [state, setState] = useState({
    documentManager: new DocumentManager(),
    loading: true,
  });

  const onResponse = (response) => {
    setState(prevState => {
      const nextState = { ...prevState, loading: response.loading };
      nextState.documentManager.addDocumentToHistoryFromData(response);

      return nextState;
    });
  };

  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  useEffect(() => {
    getDocument({
      documentId: id,
      onResponse,
      onError,
    });
  }, []);

  useEffect(() => {
    setCursorPositionInDOM({
      editableContentId: styles.mainSection,
      isibuteDocument: state.documentManager.getCurrentDocument(),
    });
  }, [state.documentManager.historyIndex]);

  if (state.loading) {
    return <DiscourseLoading />;
  }

  return (
    <>
      <DiscoursePublishingInfoSection document={state.documentManager.getCurrentDocument()} />
      <DiscourseMainSection setState={setState} state={state} />
      <DiscourseDebugger state={state} />
    </>
  );
};

export default Discourse;
