import { HTTPRequestMethods } from "../../Shared/httpRequestMethods";

export const getDocument = ({ documentId, onResponse, onError }) => {
  fetch(
    `/api/documents/${documentId}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getDocuments = ({ onResponse, onError }) => {
  fetch(
    `/api/documents`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
}

export const moveCitationHeaders = ({ onResponse, onError, body }) => {
  fetch(
    "/api/documents/move_citation_headers",
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const padUnstyledContent = ({ onResponse, onError, body }) => {
  fetch(
    "/api/documents/pad_unstyled_content_and_styling",
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const populateLocalNavigationFields = ({ onResponse, onError, body }) => {
  fetch(
    "/api/documents/populate_local_navigation_fields",
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const seedDocument = ({ onResponse, onError, body }) => {
  fetch(
    "/api/documents/seed",
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const setUnstyledContent = ({ onResponse, onError, body }) => {
  fetch(
    "/api/documents/set_all_unstyled_content_and_styling",
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};
