export const getCursorStartPostion = ({ editableContentId }) => {
  const editableDocument = document.getElementById(editableContentId);

  if (document.activeElement !== editableDocument) {
    return;
  }

  const originalRange = window.getSelection().getRangeAt(0);

  const measuringRange = originalRange.cloneRange();
  measuringRange.selectNodeContents(editableDocument);
  measuringRange.setEnd(originalRange.startContainer, originalRange.startOffset);

  return measuringRange.toString().length;
};

export const getCursorEndPostion = ({ editableContentId }) => {
  const editableDocument = document.getElementById(editableContentId);

  if (document.activeElement !== editableDocument) {
    return;
  }

  const originalRange = window.getSelection().getRangeAt(0);

  const measuringRange = originalRange.cloneRange();
  measuringRange.selectNodeContents(editableDocument);
  measuringRange.setEnd(originalRange.endContainer, originalRange.endOffset);

  return measuringRange.toString().length;
};

export const getCurrentDocumentComponentAndCursorPosition = ({ node, targetPosition }) => {
  let pos = 0;
  let lastUnstyledContentStart = 0;
  let documentComponentNode = node;
  const unstyledContentNodeNames = [
    'H1',
    'H2',
    'H3',
    'P',
    'LI',
  ];
  const stack = [node];

  while (stack.length > 0) {
      const current = stack.pop();

      if (current.nodeType === Node.TEXT_NODE) {
          const len = current.textContent.length;
          if (pos + len >= targetPosition) {
              return {
                documentComponentNode: documentComponentNode,
                documentComponentCursorPosition: targetPosition - lastUnstyledContentStart
              };
          }
          pos += len;
      } else if (current.childNodes && current.childNodes.length > 0) {
          if (unstyledContentNodeNames.includes(current.nodeName)) {
            lastUnstyledContentStart = pos;
            documentComponentNode = current;
          }

          for (let i = current.childNodes.length - 1; i >= 0; i--) {
              stack.push(current.childNodes[i]);
          }
      }
  };

  return {
    documentComponentNode: node,
    documentComponentCursorPosition: node.childNodes.length
  };
};

export const handleSelectionChange = ({ editableContentId, setState }) => {
  const cursorStartPosition = getCursorStartPostion({ editableContentId });
  const cursorEndPosition = getCursorEndPostion({ editableContentId });

  const editableDocument = document.getElementById(editableContentId);

  if (document.activeElement !== editableDocument) {
    return;
  }

  const {
    documentComponentNode: documentStartComponentNode,
    documentComponentCursorPosition: documentStartComponentCursorPosition
  } = getCurrentDocumentComponentAndCursorPosition({
    node: editableDocument,
    targetPosition: cursorStartPosition,
  });

  const {
    documentComponentNode: documentEndComponentNode,
    documentComponentCursorPosition: documentEndComponentCursorPosition
  } = getCurrentDocumentComponentAndCursorPosition({
    node: editableDocument,
    targetPosition: cursorEndPosition,
  });

  setState((prevState) => {
    const nextState = { ...prevState };
    nextState.documentManager.setCursorOnCurrentDocument({
      cursorStartPosition,
      cursorEndPosition,
      documentStartComponentCursorPosition,
      documentEndComponentCursorPosition,
      documentStartComponentNode,
      documentEndComponentNode,
    });

    return nextState;
  });
};

export const createRangeFromStartAndEndNodesAndOffsets = ({
  startNode,
  startOffset,
  endNode,
  endOffset,
}) => {
  let range = document.createRange();
  range.setStart(startNode, startOffset);
  range.setEnd(endNode, endOffset);
  return range;
};

export const getDescendantNodeAndOffset = ({ node, targetPosition }) => {
  let pos = 0;
  const stack = [node];

  while (stack.length > 0) {
    const current = stack.pop();

    if (current.nodeType === Node.TEXT_NODE) {
      const len = current.textContent.length;
      if (pos + len >= targetPosition) {
        return {
          descendantNode: current,
          offset: targetPosition - pos,
        };
      }
      pos += len;
    } else if (current.childNodes && current.childNodes.length > 0) {
      for (let i = current.childNodes.length - 1; i >= 0; i--) {
        stack.push(current.childNodes[i]);
      }
    }
  }

  return {
    descendantNode: node,
    offset: node.childNodes.length,
  };
};

export const setCursorPositionInDOM = ({
  editableContentId,
  isibuteDocument,
}) => {
  const editableDocument = document.getElementById(editableContentId);

  if (!editableDocument) {
    return;
  }

  if (document.activeElement !== editableDocument) {
    return;
  }

  const {
    descendantNode: startNode,
    offset: startOffset
  } = getDescendantNodeAndOffset({
    node: editableDocument,
    targetPosition: isibuteDocument.cursorStartPosition
  });

  const {
    descendantNode: endNode,
    offset: endOffset
  } = getDescendantNodeAndOffset({
    node: editableDocument,
    targetPosition: isibuteDocument.cursorEndPosition
  });

  const range = createRangeFromStartAndEndNodesAndOffsets({
    startNode,
    startOffset,
    endNode,
    endOffset,
  });

  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
};

export const updateFromKeydown = ({ event, setState }) => {
  event.preventDefault();

  const persistedEvent = {
    key: event.key,
    altKeyIsPressed: event.altKey,
    ctrlKeyIsPressed: event.ctrlKey,
    metaKeyIsPressed: event.metaKey,
    shiftKeyIsPressed: event.shiftKey,
  };

  setState((prevState) => {
    const nextState = { ...prevState };
    nextState.documentManager.processEvent(persistedEvent);

    return nextState;
  });
};
