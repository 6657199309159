import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import StepWizard from 'react-step-wizard';
import InvitationRequestForm from './InvitationRequestForm';
import InvitationRequestReceived from './InvitationRequestReceived';
import Feedback from '../../Feedback/components/Feedback';

import styles from './InvitationRequestWizard.module.scss';

const InvitationRequestWizard = ({
  sharedAssetsPaths,
  showInvitationRequestWizard,
  closeInvitationRequestWizard
}) => {
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackVariant, setFeedbackVariant] = useState(null);

  const clearFeedback = () => {
    setFeedbackMessage(null);
    setFeedbackVariant(null);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={showInvitationRequestWizard}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Invitation Request Wizard"
        onRequestClose={closeInvitationRequestWizard}
      >
        <StepWizard>
          <InvitationRequestForm
            closeInvitationRequestWizard={closeInvitationRequestWizard}
            sharedAssetsPaths={sharedAssetsPaths}
            setFeedbackMessage={setFeedbackMessage}
            setFeedbackVariant={setFeedbackVariant}
          />
          <InvitationRequestReceived
            closeInvitationRequestWizard={closeInvitationRequestWizard}
            sharedAssetsPaths={sharedAssetsPaths}
          />
        </StepWizard>
      </Modal>
      <Feedback
        message={feedbackMessage}
        variant={feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </React.Fragment>
  );
};

InvitationRequestWizard.propTypes = {
  sharedAssetsPaths: PropTypes.shape({}).isRequired,
};

export default InvitationRequestWizard;
