import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { createUserInvitationRequest } from './restApi';

import styles from './InvitationRequestForm.module.scss';

const InvitationRequestForm = ({
  closeInvitationRequestWizard,
  sharedAssetsPaths,
  isActive,
  nextStep,
  setFeedbackMessage,
  setFeedbackVariant
}) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [linkedInProfileUrl, setLinkedInProfileUrl] = useState(null);
  const [personalWebsiteOrBlogUrl, setPersonalWebsiteOrBlogUrl] = useState(null);

  const handleFirstNameTextChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameTextChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailTextChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLinkedInProfileUrlTextChange = (e) => {
    setLinkedInProfileUrl(e.target.value);
  };

  const handlePersonalWebsiteOrBlogUrlTextChange = (e) => {
    setPersonalWebsiteOrBlogUrl(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    createUserInvitationRequest({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          nextStep();
        } else {
          setFeedbackMessage(data.error_message || data.message);
          setFeedbackVariant("error");
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => {
        setFeedbackMessage(feedbackMessage);
        setFeedbackVariant(feedbackVariant);
      },
      body: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        linkedin_profile_url: linkedInProfileUrl,
        personal_website_or_blog_url: personalWebsiteOrBlogUrl,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    });
  };

  return isActive ? (
    <React.Fragment>
      <div id={styles.xComponentContainer}>
        <img src={sharedAssetsPaths.xComponent} id={styles.xComponent} onClick={closeInvitationRequestWizard}></img>
      </div>

      <section id={styles.mainContent}>
        <div id={styles.messageContainer}>
          <p id={styles.message}>
            Thanks for your interest in using Isibute. Isibute is for people involved in or genuinely interested in life sciences or the biotech industry. You don’t have to be a veteran scientist to use Isibute. Maybe you’re figuring out whether life sciences or biotech is right for you. You are welcome here! Maybe you are already deeply entrenched in the biotech industry but on the legal or business side rather than the scientific side. You too are welcome. Please, don’t use Isibute if you don’t have a genuine interest in life sciences or biotech.
          </p>
          <p id={styles.message}>
            Also, be kind. Everyone is at different stages in their life, career, and learning. If you still feel Isibute is right for you...
          </p>
          <p id={styles.message}>
            Please request an invitation by submitting your information below.
          </p>
        </div>

        <form onSubmit={onSubmit} id={styles.invitationRequestForm}>
          <input
            className={styles.input}
            type="text"
            id="firstName"
            placeholder="First Name:"
            onChange={handleFirstNameTextChange}
          />

          <input
            className={styles.input}
            type="text"
            id="lastName"
            placeholder="Last Name:"
            onChange={handleLastNameTextChange}
          />

          <input
            className={styles.input}
            type="email"
            id="email"
            placeholder="Email:"
            onChange={handleEmailTextChange}
          />

          <input
            className={styles.input}
            type="url"
            id="linkedinProfileUrl"
            placeholder="LinkedIn Profile (Optional):"
            onChange={handleLinkedInProfileUrlTextChange}
          />

          <input
            className={styles.input}
            type="url"
            id="personalWebsiteOrBlog"
            placeholder="Personal Website/Blog (Optional):"
            onChange={handlePersonalWebsiteOrBlogUrlTextChange}
          />

          <input
            type="submit"
            name="commit"
            value="Get an invitation"
            className={styles.getInvitationBtn}
            data-disable-with="Get an invitation"
          />
        </form>
        <div>
          <p  id={styles.nevermindCTA} onClick={closeInvitationRequestWizard}>Nevermind</p>
        </div>
      </section>
    </React.Fragment>
  ) : (
    <></>
  );
};

InvitationRequestForm.propTypes = {
  sharedAssetsPaths: PropTypes.shape({}).isRequired,
};

export default InvitationRequestForm;
