import React, { useState } from 'react';
import styles from './LandingPage.module.scss';

import InvitationRequestWizard from '../../InvitationRequestWizard/components/InvitationRequestWizard';

const LandingPage = ({ sharedAssetsPaths }) => {
  const [showInvitationRequestWizard, setShowInvitationRequestWizard] = useState(false);

  return (
    <React.Fragment>
      <section id={styles.splash} style={{backgroundImage: `url(${sharedAssetsPaths.ecosystem})`}}>
        <h1 id={styles.slogan}>Evolve, Together.</h1>
        <button id={styles.mainInvitationCTABtn} onClick={() => { setShowInvitationRequestWizard(true) }}>Get an invitation</button>
        <p id={styles.introduction}>Find community and support your journey in biotech.</p>
      </section>
      <section id={styles.descriptionContainer}>
        <p id={styles.description}>Isibute is the online ecosystem that helps biotech focused communities and organizations flourish.</p>
      </section>
      <section id={styles.featuresContainer}>
        <section id={styles.community} className={styles.featureSection}>
          <h4 className={styles.mobileFeatureTitle}>Find Community</h4>
          <div id={styles.communityFeatureContainer} className={styles.featureContainer}>
            <h4 className={styles.featureTitle}>Find Community</h4>
            <p className={styles.featureDescription}>With the rapid pace of discovery and developments in life sciences and the biotech industry, it can be hard to keep up. Don’t do it alone. Find groups and communities to help you explore your specific interests in biotech. If you can’t find one, we’ll help you start one.</p>
            <button className={styles.invitationCTABtn} onClick={() => { setShowInvitationRequestWizard(true) }}>Get an invitation</button>
          </div>
          <img id={styles.communityImage} className={styles.image} src={sharedAssetsPaths.biotechCommunity}></img>
        </section>
        <section id={styles.events} className={styles.featureSection}>
          <h4 className={styles.mobileFeatureTitle}>Attend Events</h4>
          <div id={styles.eventsFeatureContainer} className={styles.featureContainer}>
            <h4 className={styles.featureTitle}>Attend Events</h4>
            <p className={styles.featureDescription}>There is so much going on in life sciences and the biotech industry. Don’t miss out on what’s relevant to you. Find and attend events related to your specific interests in biotech. Meet people and make meaningful professional connections that will support your journey in biotech.</p>
            <button className={styles.invitationCTABtn} onClick={() => { setShowInvitationRequestWizard(true) }}>Get an invitation</button>
          </div>
          <img id={styles.eventsImage} className={styles.image} src={sharedAssetsPaths.biotechEvent}></img>
        </section>
        <section id={styles.jobs} className={styles.featureSection}>
          <h4 className={styles.mobileFeatureTitle}>Find And Post Jobs</h4>
          <div id={styles.jobsFeatureContainer} className={styles.featureContainer}>
            <h4 className={styles.featureTitle}>Find And Post Jobs</h4>
            <p className={styles.featureDescription}>Even in biotech, it can be hard to find the perfect job for you. On the other hand, as a recruiter it can be tough to find the right qualified candidates for a role. Isibute helps bridge the gap between those seeking the perfect opportunity and those looking for the perfect person to take it on.</p>
            <button className={styles.invitationCTABtn} onClick={() => { setShowInvitationRequestWizard(true) }}>Get an invitation</button>
          </div>
          <img id={styles.jobsImage} className={styles.image} src={sharedAssetsPaths.biotechJob}></img>
        </section>
        <section id={styles.discourse} className={styles.featureSection}>
          <h4 className={styles.mobileFeatureTitle}>Discourse</h4>
          <div id={styles.discourseFeatureContainer} className={styles.featureContainer}>
            <h4 className={styles.featureTitle}>Discourse</h4>
            <p className={styles.featureDescription}>Life sciences and the biotech industry are deeply impactful. Among other benefits, our work produces life saving treatments. The state of the industry, how we think about our work, our decisions, and our actions make an impact. Let’s talk about it. Join the conversation.</p>
            <a href="/discourse">
              <button className={styles.invitationCTABtn}>Go To Discourse</button>
            </a>
          </div>
          <img id={styles.discourseImage} className={styles.image} src={sharedAssetsPaths.biotechDiscourse}></img>
        </section>
      </section>

      <InvitationRequestWizard
        sharedAssetsPaths={sharedAssetsPaths}
        showInvitationRequestWizard={showInvitationRequestWizard}
        closeInvitationRequestWizard={() => { setShowInvitationRequestWizard(false)} }
      />
    </React.Fragment>
  );
};

export default LandingPage;
